import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApplicationService } from './application.service';
import { ApplicationProgress } from '../../shared/enums/general';

@Injectable({
    providedIn: 'root'
})
export class ApplicationNavGuard implements CanActivate {

    constructor(private appService: ApplicationService, private router: Router, private route: ActivatedRoute) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        let steps = this.appService.steps;
        if (steps) {
            let count = steps.length - 1;
            let i = 0;
            for (i; i <= count; i++) {
                let route = steps[i].route;
                if (state.url.endsWith(route)) {
                    console.log(steps[i].title + " is active");
                    return true;
                }

                if (!steps[i].completed) {
                    console.log(steps[i].title + " is active");
                    this.router.navigate(["./" + steps[i].route], { relativeTo: this.route.firstChild });
                    return false;
                }
            }
        }
        return true;
    }
}
