<h3>Producer Information</h3>

<form id="information-container" *ngIf="appService.producer" submit>
    <div class="half-width inline top first-half-option">
        <div>
            <mat-form-field class="full-width">
                <input name="computed-name" class="proper-name" matInput disabled type="text" [(ngModel)]="appService.producer.fullName" placeholder="Name" />
            </mat-form-field>
            <div>
                <mat-form-field class="half-width first-half-option" *ngIf="appService.producer.type === 'Agent'">
                    <input name="dob" matInput disabled [(ngModel)]="appService.producer.dateOfBirth" [matDatepicker]="dobDatePicker" placeholder="Date of Birth">
                    <mat-datepicker-toggle matSuffix [for]="dobDatePicker"></mat-datepicker-toggle>
                    <mat-datepicker #dobDatePicker></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </div>

    <div class="half-width inline top">
        <div>
            <mat-form-field class="half-width">
                <input name="npn" matInput type="text" disabled [(ngModel)]="appService.producer.npn" placeholder="National Producer Number (NPN)" />
            </mat-form-field>
            <mat-form-field class="full-width" *ngIf="appService.producer.type == 'Agency'">
                <input name="fein" matInput type="text" disabled [(ngModel)]="appService.producer.fein" placeholder="FEIN" />
            </mat-form-field>
            <div>
                <mat-form-field class="state-short inline">
                    <input name="res-state" matInput disabled type="text" [(ngModel)]="appService.producer.stateCode"  placeholder="'Resident State'" />
                </mat-form-field>
                <mat-form-field class="half-width">
                    <input name="license" matInput disabled type="text" [(ngModel)]="appService.producer.licenseNumber" placeholder="License Number" />
                </mat-form-field>
            </div>
        </div>
    </div>

    <h3 style="padding-top: 15px; margin-bottom: 10px;">Contact Information</h3>
    <div class="half-width inline top first-half-option">
        <div>
            <mat-form-field class="full-width">
                <input name="bizPhone" matInput disabled [ngModel]="appService.producer.contact.bizPhone | appPhoneMask" placeholder="Business Phone" title="Format: 123-456-7890" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <input name="cellPhone" matInput (ngModelChange)="appService.producer.contact.cellPhone = $event" [ngModel]="appService.producer.contact.cellPhone | appPhoneMask" placeholder="Cell Phone" title="Format: 123-456-7890" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <input name="otherPhone" matInput (ngModelChange)="appService.producer.contact.otherPhone = $event" [ngModel]="appService.producer.contact.otherPhone | appPhoneMask" placeholder="Other Phone" title="Format: 123-456-7890" />
            </mat-form-field>
        </div>
    </div>
    <div class="half-width inline top">
        <div>
            <mat-form-field class="full-width">
                <input name="email" matInput type="text" disabled [(ngModel)]="appService.producer.contact.email" placeholder="E-mail" />
            </mat-form-field>
            <mat-form-field class="full-width">
                <input name="website" matInput type="text" [(ngModel)]="appService.producer.contact.website" placeholder="Website" />
            </mat-form-field>
            <mat-form-field class="half-width">
                <mat-label>Preferred Contact</mat-label>
                <mat-select name="preferredContact" [(ngModel)]="appService.producer.contact.preferredContact">
                    <mat-option [value]="'bizPhone'">Business Phone</mat-option>
                    <mat-option [value]="'cellPhone'">Cell Phone</mat-option>
                    <mat-option [value]="'otherPhone'">Other Phone</mat-option>
                    <mat-option [value]="'email'">E-mail</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </div>
    <div style="margin-top: 30px;">
        <div class="third-width inline top">
            <div>
                <h4>Business Address</h4>
                <mat-form-field class="full-width">
                    <input name="ba-line1" matInput type="text" disabled [(ngModel)]="appService.producer.contact.businessAddress.line1" required placeholder="Street Address" />
                </mat-form-field>
                <mat-form-field class="full-width">
                    <input name="ba-line2" matInput type="text" disabled [(ngModel)]="appService.producer.contact.businessAddress.line2" placeholder="Address Line 2" />
                </mat-form-field>
                <div class="full-width">
                    <mat-form-field class="full-width">
                        <input name="ba-city" matInput type="text" disabled [(ngModel)]="appService.producer.contact.businessAddress.city" required placeholder="City" />
                    </mat-form-field>
                    <mat-form-field class="state-short">
                        <mat-label>State</mat-label>
                        <mat-select name="ba-state" disabled [(ngModel)]="appService.producer.contact.businessAddress.state">
                            <mat-option *ngFor="let state of appService.statesList" [value]="state">{{state}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="third-width">
                        <input name="ba-zip" matInput type="text" disabled [(ngModel)]="appService.producer.contact.businessAddress.zip" required placeholder="Zip" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="third-width inline top middle-option">
            <div>
                <h4 class="inline">Mailing Address</h4>
                <!--<label>
                    <mat-checkbox class="med-gray heading-checkbox" name="ma-dupe" [(ngModel)]="mailingSameAsBusiness" (change)="updateMailing()"></mat-checkbox>
                    Same as Business
                </label>-->
                <mat-form-field class="full-width">
                    <input name="ma-line1" matInput type="text" disabled [(ngModel)]="appService.producer.contact.mailingAddress.line1" placeholder="Street Address" />
                </mat-form-field>
                <mat-form-field class="full-width">
                    <input name="ma-line2" matInput type="text" disabled [(ngModel)]="appService.producer.contact.mailingAddress.line2" placeholder="Address Line 2" />
                </mat-form-field>
                <div class="full-width">
                    <mat-form-field class="full-width">
                        <input name="ma-city" matInput type="text" disabled [(ngModel)]="appService.producer.contact.mailingAddress.city" placeholder="City" />
                    </mat-form-field>
                    <mat-form-field class="state-short">
                        <mat-label>State</mat-label>
                        <mat-select name="ma-state" disabled [(ngModel)]="appService.producer.contact.mailingAddress.state">
                            <mat-option *ngFor="let state of appService.statesList" [value]="state">{{state}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="third-width">
                        <input name="ma-zip" matInput type="text" disabled [(ngModel)]="appService.producer.contact.mailingAddress.zip" placeholder="Zip" />
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="third-width inline top">
            <div>
                <h4 class="inline">Residential Address</h4>
                <!--<label>
                    <mat-checkbox class="med-gray heading-checkbox" name="ra-dupe" [(ngModel)]="resSameAsMailing" (change)="updateResidential()"></mat-checkbox>
                    Same as Mailing
                </label>-->
                <mat-form-field class="full-width">
                    <input name="ra-line1" matInput type="text" disabled [(ngModel)]="appService.producer.contact.residentialAddress.line1" placeholder="Street Address" />
                </mat-form-field>
                <mat-form-field class="full-width">
                    <input name="ra-line2" matInput type="text" disabled [(ngModel)]="appService.producer.contact.residentialAddress.line2" placeholder="Address Line 2" />
                </mat-form-field>
                <div class="full-width">
                    <mat-form-field class="full-width">
                        <input name="ra-city" matInput type="text" disabled [(ngModel)]="appService.producer.contact.residentialAddress.city" placeholder="City" />
                    </mat-form-field>
                    <mat-form-field class="state-short">
                        <mat-label>State</mat-label>
                        <mat-select name="ra-state" disabled [(ngModel)]="appService.producer.contact.residentialAddress.state">
                            <mat-option *ngFor="let state of appService.statesList" [value]="state">{{state}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field class="third-width">
                        <input name="ra-zip" matInput type="text" disabled [(ngModel)]="appService.producer.contact.residentialAddress.zip" placeholder="Zip" />
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <div id="information-alert">
        If any of the above information is incorrect, please update it with the appropriate state DOI.
    </div>

    <a class="button float-right page-next" (click)="completeStep()">{{appService.nextStepBtnText}}</a>

</form>

