
<header>
  <div class="container">
    <nav>
      <div id="login">
        <span *ngIf="!(authService.authState$ | async)?.isAuthenticated">
          <a id="login-button" class="button" (click)="login()">Please Login</a>
        </span>
        <span *ngIf="(authService.authState$ | async)?.isAuthenticated && (userService.name$ | async) as name">
          <span class="nav-text text-dark proper-name" title="{{userService.email}}">Hello, {{name}}</span>
          <a class="button" (click)="logout()">Sign out</a>
        </span>
      </div>
      <div id="logo-contain">
        <a href="./"><img id="logo" src="./assets/images/logo-tmhcc.svg" alt="Tokio Marine HCC" /></a>
        <h1 id="page-title">{{pageTitle}}</h1>
      </div>
    </nav>
  </div>
</header>


<div *ngIf="!isDocuSignRedirect">
  <div class="container">
    <router-outlet></router-outlet>
  </div>

  <div class="container" *ngIf="!(authService.authState$ | async)?.isAuthenticated">
      <div style="display: inline-block; width: 100%;" *ngIf="!(authService.authState$ | async)?.isAuthenticated">
          <div class="float-right" style="font-size: 1.4em; color: #f13535;">
              Contact support at <a href="mailTo:ipa-support@tmhcc.com">ipa-support@tmhcc.com</a><br />if you have problems signing in
          </div>
      </div>
      <div>
          <h2 style="margin-top: 200px;">Welcome to Producer Onboarding</h2>
      </div>
  </div>
</div>

<div class="container" style="margin-top: 50px;">
  <div><p>{{message}}</p></div>

  <div *ngIf="isDocuSignRedirect">
    <p style="margin-top: 50px;">This page will close automatically...</p>
  </div>
</div>

<pv-modal id="loading-overlay" class="no-background">
  <div id="loading-container">
    <div class="loading-spinner"></div>
  </div>
</pv-modal>

<pv-modal id="message-overlay">
  <span>{{message}}</span>
</pv-modal>
